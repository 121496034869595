@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary: #405189;
  --dark-green: #0D3C45;
  --lighten-green: #0d3c450a;

}

//  * -------------------------- Font Family Montserrat --------------  *

.font-family-montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.catalog-logo {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: white;

}

// * --------------------- Customer Scroll Bar {{ START }} --------------- *
/* Width And Height */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888892 !important;
  border-radius: 4px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888888c0 !important;
}

// * --------------------- Customer Scroll Bar {{ END }} --------------- *

// * --------------------- Nav Menu {{ START }} --------------- *
.navbar-menu .navbar-nav .nav-sm .nav-link:before {
  border-radius: 50px !important;
  height: 6px;
}

.navbar-menu .navbar-nav .nav-link {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
}

.navbar-menu .navbar-nav .nav-link.active[data-bs-toggle="collapse"] {
  background-color: #8197dd54;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
}

.navbar-menu .navbar-nav .nav-link.active[data-bs-toggle="collapse"]:after {
  content: "\F0140" !important;
}

.navbar-menu .navbar-nav .nav-link {
  margin-left: 5px;
  margin-right: 5px;
}

.header-icons {
  font-size: 25px !important;
  cursor: pointer;
}

.profile-cart-circle-div {
  position: absolute;
  top: -7px;
  right: -8px;
  background-color: var(--dark-green);
  padding: 1px 8px;
  border-radius: 100px;

  span {
    font-size: 13px;
    font-weight: 500;
    color: white;
  }
}

.text-capitlize {
  text-transform: capitalize;
}

.resize-none {
  resize: none;
}

.product-filter-label {
  font-size: 18px;
  margin-left: 10px;
  font-weight: 500;
  color: white;
}

.catalog-name-logo {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--dark-green);
}

.sidebar-logo {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 600;
  color: white !important;
}

.single-name-logo {
  padding-left: 12px !important;
}

.menu-title-border-li {
  border-top: 1px solid rgba(255, 255, 255, 0.303);
  padding-top: 10px;
  margin-top: 20px;
}

.main-header-links-wrapper {
  margin-top: 22px;

  .wraper-content {
    display: flex;
    align-items: center;

    .nav-icons {
      font-size: 16px;
    }

    a {
      margin-left: 8px;
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 500;
      }


    }

    :focus {
      color: var(--dark-green);
      text-decoration: underline;
    }


  }
}

// * --------------------- Nav Menu {{ END }} --------------- *

// * --------------------- Use Common Css {{ START }} --------------- *
//  * ---------------- Required Dot ----------- *

.required-text-color {
  color: red;
}

.text-error {
  font-size: 11.375px;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f06548;
}

//  * ---------------- Three Dot Text  ----------- *
.three-dot-text {
  display: inline-block;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.three-dot-text-catalog {
  display: inline-block;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.three-dot-text-porduct-master {
  display: inline-block;
  max-width: 370px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.three-dot-text-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.three-dot-text-one-lines {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-quote-details-primary-heading {
  font-size: 10px;
  padding: 9px 0;
  height: 18px !important;
  line-height: 0 !important;
  width: 116px;
  margin-bottom: 10px;
}

.search-option-icon {
  font-size: 33px;
  color: var(--dark-green);
}

.common-background-btn {
  background-color: var(--dark-green);
}

.pagination-table-background {
  background-color: #F3F3F9 !important;
}

//  * ---------------- Modal Image Display  ----------- *
.modal-image-display {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-right-0 {
  padding-left: 0 !important;
}

.margin-left-15 {
  margin-left: 15px;
}

.common-table-height {
  max-height: 315px;
  overflow: auto;

  .t-head-height {
    position: sticky;
    top: 0
  }
}

.wishlist-hover-div {
  position: relative;

  &:hover {
    .common-hovers-tooltip {
      display: block;
      position: absolute;
      bottom: -22px;
      left: -23px;
      background-color: #131313;
      color: white;
      padding: 3px 10px;
      font-size: 12px;
      border-radius: 5px;
      z-index: 99;
    }
  }
}

.common-hovers-tooltip {
  display: none;
}

.wrapper-filters-product {
  .main-global-shipping {
    width: 100% !important;
  }
}

// * --------------------- Use Common Css {{ END }} --------------- *

// * ----------------  Search Option {{ START }} --------------- *

.parent--search-bar--div {
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }

  .css-1u9des2-indicatorSeparator {
    display: none !important;
  }

  .css-1xc3v61-indicatorContainer {
    display: none !important;
  }

  .css-13cymwt-control {
    background: #f3f3f9;
  }

  .css-t3ipsp-control {
    background: #f3f3f9;
  }

  .search-moal-header .css-1fdsijx-ValueContainer {
    margin-right: 35px;
  }
}

// * ----------------  Search Option {{ END }} --------------- *

.sign-up-text-div {
  font-size: 14px;

  font-weight: 500;

  .signup-link {
    text-decoration: underline;
  }

}

// * ----------------  Login {{ START }} --------------- *

.main--parent--login--div {
  background-color: white;

  .left-side-col {
    height: 100vh;
  }

  .welcome-to-text {
    color: #131313;
    font-weight: 600;
    margin-right: 10px;
  }

  .catalog-hand-text {
    color: var(--dark-green);
    font-weight: 600;
  }

  input {
    height: 45px !important;
    background: white !important;
    border: 1px solid #252525 !important;
    color: #131313;
  }

  input:focus {
    color: #131313;
  }

  label {
    color: #131313;
  }

  .main-login-button button {
    color: white;
    height: 46px !important;
    background: var(--dark-green) !important;
    border: 1px solid var(--dark-green) !important;
    font-size: 16px;
    font-weight: 500;
  }

  .main-login-button button:hover {
    color: white;
  }

  .main-login-button button:active {
    color: white;
  }

  .main-input-padding-set {
    padding: 0px 180px;
  }

  .main-input-padding-set .end-0 {
    top: 4px !important;
  }

  .main-logo-class-padding {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--dark-green);

    h1 {
      color: white;
      font-weight: 700;
      font-family: inherit;
      text-transform: uppercase;
      font-size: 80px;
      letter-spacing: 2px;
    }
  }
}

// * ----------------  Login {{ END }} --------------- *


// * ----------------  Customer Details {{ START }} --------------- *

.modal-customer-details {

  .disaply-grid-shipment {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }

  .main-details-details .main-details-div {
    padding: 0 10px 0 10px;
  }

  .disaply-grid-desc {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 10px;
  }

  .description-height-manage {
    max-height: 250px !important;
    overflow: auto;
  }

  .customer-details-value {
    font-size: 15px;
    padding-left: 10px;

  }

  .details-main-div .details-labels {
    font-size: 15px;
    font-weight: 500;
    padding-right: 5px;
  }

  .details-main-div {
    display: flex;
    align-items: center;
    border: 1px solid #e9ebec;
    padding: 10px 10px 10px 10px;
  }
}

.customer-detail-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

// * ----------------  Customer Details {{ END }} --------------- *

// * ----------------  Customer Registration {{ START }} --------------- *

.main--customer--registration--div {
  background-color: white;

  .back-btn-div {
    font-size: 30px;
    position: absolute;
    left: 5px;

    top: 0;

    i {
      color: var(--dark-green);
    }
  }

  .welcome-to-text {
    color: #131313;
    font-weight: 600;
    margin-right: 10px;
  }

  .catalog-hand-text {
    color: var(--dark-green);
    font-weight: 600;
    padding-top: 4px;
  }

  input {
    height: 45px !important;
    background: white !important;
    border: 1px solid #252525 !important;
    color: #131313;
  }

  input:focus {
    color: #131313;
  }

  label {
    color: #131313;
  }

  .main-login-button button {
    color: white;
    height: 46px !important;
    background: var(--dark-green) !important;
    border: 1px solid var(--dark-green) !important;
    font-size: 16px;
    font-weight: 500;
  }

  .main-login-button button:hover {
    color: white;
  }

  .main-login-button button:active {
    color: white;
  }

  .main-input-padding-set {
    padding: 0px 80px;
  }

  .main-input-padding-set .end-0 {
    top: 4px !important;
  }

  .main-logo-class-padding {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--dark-green);

    h1 {
      color: white;
      font-weight: 700;
      font-family: inherit;
      text-transform: uppercase;
      font-size: 80px;
      letter-spacing: 2px;
    }
  }

  .main-phone-input input {
    height: 45px !important;
    background: white !important;
    border: 1px solid #252525 !important;
    color: #131313;
    outline: none !important;
    padding-left: 5px !important;
    font-family: "Nunito" !important;
    color: black !important;
    font-size: 14px !important;
    padding-left: 50px !important;
    width: 100%;
  }

  .flag-dropdown {
    height: 45px !important;
    background: white !important;
    border: 1px solid #252525 !important;
    color: #131313;
    outline: none !important;
    padding-left: 5px !important;
    font-family: "Nunito" !important;
    color: black !important;
    font-size: 14px !important;

  }

  .customer-address-div {
    border: 1px solid #252525 !important;
    resize: none;
  }

  .main-phone-input input::placeholder {
    color: #9AA8BC !important;
  }

  .main-phone-input input:focus {
    outline: none !important;
  }

  .business-card-div input {
    height: auto !important;
    padding: 11px !important
  }

  .phone-input-main-div {
    width: 140px;
    z-index: 99;
  }

  .mobile-input-main-div {
    width: 100%;
    margin-left: 5px;

    div {
      margin-bottom: 0 !important;
    }
  }

  .common-icon {
    width: 40px;
    background: white;
    height: 43px !important;
    margin-top: 1px;
    justify-content: center !important;
    right: 1px !important;
    border-radius: 0 3px 3px 0;
    border-left: 1px solid black;
  }
}

// * ----------------  Customer Registration {{ END }} --------------- *

// * ----------------  Product Page {{ START }} --------------- *
.parent--product--page-section {
  .wrapper--product-cards {
    position: relative;

    .product-card {
      font-family: "Montserrat", sans-serif !important;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border: 1px solid #F9F9F9;
      border-radius: 10px;
      display: flex;
      padding: 20px 10px 20px 20px;
      height: 450px;


      .sub-category-style-wrap-div {
        position: absolute;
        bottom: 10px;
        z-index: 9;
        left: 20px;
      }

      .stock-id-span {
        font-size: 16px;
        font-weight: 600;
        color: #1A1A1A;
      }

      .sub-category-span {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      .product-name-span {
        font-size: 16px;
        margin-top: 5px;
        line-height: 25px;
        font-weight: 500;
        color: #4B4A42;
        width: 330px;
      }

      .product-img-div {
        margin-top: 10px;
        height: 330px;
        // height: 385px;
        position: relative;
        margin-right: 10px;
        // border-bottom: 1px solid #8080807a;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

        }

        .stock-number-div {
          position: absolute;
          left: 0;
          padding: 5px 15px;
          bottom: 0;

          span {
            font-size: 12px;
            font-weight: 600;
            color: #000000e7;
          }
        }
      }

      .gram-price-div {
        border-bottom: 1px solid #F5F5F5;
        padding-top: 4px;
      }

      .gram-price-div span {
        font-size: 14px;
        font-weight: 500;
        color: #151212;
        margin-bottom: 5px;
      }

      .gram-text-span {
        font-size: 16px !important;
        font-weight: 600 !important;
        border-top: 1px solid #80808047;
        padding-top: 6px;
        border-bottom: 1px solid #80808047;
        padding-bottom: 6px;
      }

      .main-wraper-customer-products {
        max-height: 414px;
        overflow: hidden;

      }

      .main--wrapper--right-div {
        height: 210px;
        flex-direction: column;
        display: flex;
        justify-content: space-between;

        .attrbiute-option-main-div {
          padding-left: 20px;
        }

        .attribute-name-span {
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 500;
          color: #4B4A42;
          margin-right: 20px;
          margin-bottom: 0 !important;
          // width: 140px;
        }

        .option-name-span {
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 600;
          color: #1A1A1A;
          // width: 130px;
        }

        .two-dots-name {
          font-size: 14px;
          color: black;
          font-weight: 600;
          margin-right: 10px;
        }

        .subcategory-main-div {
          padding-left: 20px;
          padding-top: 5px;
          padding-bottom: 5px;
          border-top: 1px solid rgba(128, 128, 128, 0.255);
          text-transform: capitalize;
        }
      }

      .wrapper-right-content-div {
        .jewellery-name-div {
          margin-bottom: 10px;
          width: auto;

          h4 {
            font-family: "Montserrat", sans-serif !important;
            padding-left: 20px;
            padding-top: 10px;
            font-size: 15px;
            font-weight: 500;
            color: black;
          }

          .sub-category-text {
            padding-left: 20px;
            padding-top: 10px;
            font-size: 16;
          }
        }

        .jewelery-circle {
          width: 30px;
          height: 30px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          margin-top: 5px;

          span {
            font-size: 15px;
            color: white;

          }
        }

        .background-gold {
          background-color: goldenrod;
        }

        .background-skin {
          background-color: #ecbcb4;
        }

        .background-gray {
          background-color: rgba(128, 128, 128, 0.754);
        }

        .logo-div img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }

        .wrapper-pears {
          margin-left: 15px;
        }
      }

      .catalog-product-wrapper {
        margin: 20px 10px 0 10px;

        .wrapper-div {
          border-bottom: 1px solid rgba(128, 128, 128, 0.184);
          margin-bottom: 10px;
          padding-bottom: 10px;

          &:last-child {
            border: none !important;
          }

          .label-span {
            font-size: 15px;
            font-weight: 500;
            opacity: 0.7;
            padding-bottom: 4px;
          }

          .value-span {
            font-size: 15px;
            font-weight: 500;
          }

        }
      }
    }

  }

  .customer--product--card-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .product-details-div {
    position: absolute;
    top: 10px;
    left: 15px;
    cursor: pointer;

    i {
      background: #405489;
      color: white;
      padding: 6px;
      border-radius: 50px;
    }
  }

  .heart {
    color: black;
  }

  .heart.active {
    color: red;
  }

  .product-wish-list {
    position: absolute;
    top: 7px;
    right: 15px;
    cursor: pointer;

    i {
      color: #4b4a4af4;
      font-size: 20px;
    }
  }
}

.customer--product--wrapper {
  .customer-pagination-div {
    background-color: white;
    padding: 15px 15px 0 15px;
    margin-bottom: 10px;
  }
}

.product-details-modals {

  .product-details-select-box {
    width: 230px;
    margin-right: 10px;
  }
}

.default-option-label {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-bulk-error-modal {
  .wrapper-modal-body {
    max-height: 600px;
    overflow: auto;
  }

  .ul-wraper {
    padding-left: 15px;
  }

  .li-wrap {
    list-style: auto;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    border: 1px dashed #808080;
  }

  .heading-key {

    padding-left: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px dashed #1a1a1acf;
    padding-bottom: 10px;
  }

  .row-ul-wrpaer {
    padding-left: 30px;
  }

  .row-heading-text {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 6px;
  }

  .row-no-span {
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 500;
  }

  .child-ul-wrapper {
    margin-bottom: 15px;
    font-size: 14px;
    padding-left: 10px;
  }
}

// * ----------------  Product Page {{ END }} --------------- *

// * ---------------- Customer Product Details Page {{ START }} --------------- *
.customer-product-details-div {
  .product-details-title {
    font-size: 20px;
  }

  .product-img-wrapper-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-left: 30px;

    img {
      width: 450px;
      height: 450px;
    }

    video {
      width: 450px;
      height: 450px;

    }
  }

  .product-details-video {
    object-fit: cover;
    padding: 5px;
  }

  .product-details-data-div {
    margin-left: 60px;

    .stock-id-text {
      font-size: 16px;
      font-weight: 500;
    }

    .sub-category-text {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .wrapper-attribute-text-div {
    .attribute-text {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      color: rgba(31, 30, 30, 0.975);
      margin-right: 20px;
      margin-bottom: 0 !important;
    }

    .wrapper-width {
      width: 170px !important;
    }

    .two-dots-spans {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-right: 10px;
    }

  }

  .product-details-primary-heading {
    font-size: 10px;
    padding: 9px 0;
    width: 126px;
    margin-bottom: 10px;
  }


  .product-details-common-select {
    width: 200px !important;

    div:nth-child(1) {
      margin-bottom: 0 !important;
    }
  }

  .product-details-common-label-div {
    width: 200px !important;

    .label-disabled-color {
      background: #f2f2f2;
      margin-bottom: 0 !important;
    }
  }

  .radio-btn-label {
    color: #090909 !important;
  }

  .option-text {
    font-size: 17px;
    font-weight: 500;
    color: #1f1e1e;
  }

  .product-description {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 400;
    border-top: 1px solid rgba(128, 128, 128, 0.45);
    border-bottom: 1px solid rgba(128, 128, 128, 0.45);
    padding-bottom: 10px;
  }

  .customer-react-select-div {
    width: 140px;

    .css-13cymwt-control {
      .css-qfsyed-indicatorContainer {
        display: none !important;
      }

    }

    .css-10remt6-indicatorContainer {
      display: none !important;
    }
  }

  .customer-style-master-select {
    width: 200px;

    .css-13cymwt-control {
      .css-qfsyed-indicatorContainer {
        display: none !important;
      }
    }

    .css-10remt6-indicatorContainer {
      display: none !important;
    }
  }

  .attrbiute-options-heading {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: 600;
    color: rgba(31, 30, 30, 0.883);
  }

  .product-attribute-option-div {
    padding-top: 10px;
    display: grid;
    flex-direction: column;
    gap: 0px 20px;
  }

  .qty-label {
    font-size: 15px;
    font-weight: 500;
    color: rgba(31, 30, 30, 0.975);
    margin-right: 10px;
  }

  .parent-product-attrbute-wrapper {
    padding-top: 5px;
    padding-bottom: 10px;
    border-top: 1px solid rgba(128, 128, 128, 0.34);
    border-bottom: 1px solid rgba(128, 128, 128, 0.34);
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    margin-bottom: 20px;
  }

  .wrapper-product-details-div {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;

    .custom-label {
      width: 160px;
      font-size: 13px;
      font-weight: 500;
      color: #000000fa;
      margin-right: 10px;
    }

    .product-details-text {

      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      color: rgb(31, 30, 30);

    }

    .two-dots-span {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-right: 10px;
    }

    .product-input-width {
      .input-step input {
        width: 5rem !important;
      }
    }

    .description-details-div {
      font-size: 13px;
      font-weight: 500;
      text-transform: capitalize;
      color: #00000091;
      letter-spacing: 0.3px;
      width: 400px;
      padding-top: 6px;
    }
  }

  .accodian-wrapper-div {
    .accordion-item {
      border: none !important;
    }

    .accordion-button {
      padding: 12px 0px 10px 0px !important;
      background: none !important;
      border-bottom: 1px solid #80808042;
      font-family: "Montserrat", sans-serif !important;
      font-size: 14px;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    .accordion-button.collapsed {
      border-radius: 0 !important;
      border-bottom: 1px solid #80808042;
    }
  }

  .product-details-border-class {
    padding-bottom: 5px;
    margin-bottom: 0 !important;
  }

  .customer-product-qty-div {
    border-bottom: 1px solid rgba(128, 128, 128, 0.306);
    padding-bottom: 15px;
    padding-top: 10px;

    .input-step input {
      width: 5rem !important;
    }
  }

  .product-notes-div {
    textarea {
      resize: none;
    }
  }

  .other-details-name {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: rgba(31, 30, 30, 0.975);
    margin-right: 10px;
  }

  .wrapper-details-value-textbox {
    margin: 0 !important;
    width: 200px !important;

    div:nth-child(1) {
      margin-bottom: 8px !important;
    }
  }
}

.main-parent-customer-quote-div {
  margin-bottom: 20px;

  .attrbiute-options-heading {
    text-transform: capitalize;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-weight: 600;
    color: #1A1A1A;
  }

  .product-details-title {
    font-size: 22px;
  }

  .main-wrap-row {
    padding: 10px 15px 10px 15px;
  }

  .add-to-quote-qty-div {

    .input-step input {
      width: 4rem !important;
    }
  }

  .wrapper-qty-notes-div {
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .wrapper-quote-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #a8a8a814;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;

    .product-details-row {
      margin-left: 5px;
      margin-right: 10px;

    }

    .wrapper-price-div {
      position: absolute;
      right: 20px;
      top: 20px;
      background-color: #073A3D;
      z-index: 9;
      // width: 110px;
      padding-left: 20px;
      padding-right: 20px;
      height: 35px;
      border-radius: 5px;

      .price-span {
        color: #F5F5F5;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .product-img-wrapper-div {
      width: 100%;
      height: 100%;

      .quote-img {
        padding: 10px;
        border-right: 1px solid #a8a8a82b;
        width: 380px;
        height: 100%;
        object-fit: cover;
      }
    }

    .delete-icon-div {
      position: absolute;
      z-index: 99;
      right: 20px;
      top: 15px;
      cursor: pointer;
    }

    .delete-icon-quote {
      width: 50px;
      font-size: 23px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background: #f8f8f8;
      border-radius: 100px;
      border: 1px solid #0000000a;
    }

    .edit-quote-btn-div {
      button {
        width: 120px;
      }
    }
  }

  .wrapper-quote-details-div {
    display: flex;
    align-items: baseline;
    margin-top: 5px;
    margin-right: 10px;

    .custom-label {
      text-transform: capitalize;
      font-size: 14px;
      letter-spacing: 0.3px;
      font-weight: 500;
      color: #575757;
      margin-right: 10px;

    }

    .main-product-details-span {
      font-size: 18px;
      font-weight: 500;
      color: #1A1A1A;
    }



    .product-details-text {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      color: #1A1A1A;
      border-right: 1px solid #a8a8a876;
      padding-right: 10px;
    }

    .border-right-none {
      border-right: none !important;

    }

    .two-dots-span {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-right: 15px;
    }
  }

  .wrapper-quote-attribute-div {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    // margin-right: 60px;
    margin-top: 5px;
    // position: relative;

    .custom-label {
      text-transform: capitalize;
      font-size: 13px;
      letter-spacing: 0.3px;
      font-weight: 500;
      color: #2e2e2ec9;
    }

    .product-details-text {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      color: #1A1A1A;
      margin-top: 3px;

    }

    .two-dots-span {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-right: 20px;
    }
  }

  .note-content-div {

    // margin-bottom: 20px;

    .ask-to-quotation-span {
      font-weight: 500;
    }
  }

  .attribute-wrapper-row {
    max-height: 115px;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 5px 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #8080801e;
  }

  .product-quotaation-wrapper-div {

    max-height: 170px !important;
  }
}

.customer-product-master-page {
  .customer-react-select-div {
    .css-13cymwt-control {
      .css-qfsyed-indicatorContainer {
        display: none !important;
      }
    }

    .css-10remt6-indicatorContainer {
      display: none !important;
    }
  }

  .wrapper-style-masters {
    display: flex;
    align-items: baseline;

    .wrap-content {
      margin-right: 10px;
    }

    .customer-react-select-div {

      input {
        width: 120px !important;
      }
    }
  }

  .product-master-heading {
    font-size: 18px !important;
    font-weight: 600;
  }
}

.multi-select-manage-height-div {
  width: 260px !important;

  .css-b62m3t-container {
    .css-13cymwt-control:focus {
      height: 100% !important;

      .css-3w2yfm-ValueContainer {
        height: 100%;
      }

      .css-1dyz3mf {
        height: 100%;
      }
    }
  }

  .css-t3ipsp-control {
    height: 30px !important;
  }
}

.multi-select-manage-height-div {
  .css-b62m3t-container {
    .css-13cymwt-control {
      height: 30px !important;

      .css-3w2yfm-ValueContainer {
        height: 30px !important;
      }

      .css-1dyz3mf {
        height: 30px !important;
      }
    }
  }

  .css-t3ipsp-control {
    height: 100% !important;
  }
}

// * ---------------- Customer Product Details Page {{ END }} --------------- *

// * ---------------- Customer Wish List Page {{ START }} --------------- *

.main-parent-wishlist-page-div {
  .wishlist-details-title {
    font-size: 23px;
    font-weight: 500;
    margin-top: 8px;
    color: rgba(65, 63, 63, 0.874);
    padding-bottom: 5px;
  }

  .product-details-items {
    font-size: 16px;
    font-weight: 500;
    color: rgba(23, 22, 22, 0.967);
  }

  .wrapper-wishlist-container {
    padding: 0 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px 20px;

    .wishlist-card {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      position: relative;
      border-radius: 10px;

      .delete-icon-div {
        position: absolute;
        top: 10px;
        right: 15px;
        background-color: rgb(243, 240, 240);
        border-radius: 50px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        z-index: 9;

        i {
          font-size: 17px;
          color: red;
        }
      }

      .product-wishlist-details {

        position: absolute;
        top: 10px;
        left: 15px;
        cursor: pointer;

        i {
          background: #405489;
          color: white;
          padding: 6px;
          border-radius: 50px;
        }
      }

      .wishlist-product-img-div {
        width: 100%;
        height: 80%;
        position: relative;
        border-bottom: 1px solid #8080804f;


        img {
          width: 100%;
          height: 100%;
          border-radius: 10px 10px 0 0;
        }
      }

      .wishlist-details-div {
        margin: 13px 10px;

        .label-name-wishlist {
          width: 115px;
          font-size: 14px;
          font-weight: 500;
          opacity: 0.8;
        }

        .two-dots-span-wishlist {
          font-size: 16px;
          color: black;
          font-weight: 600;
          margin-right: 10px;
        }

        p {
          width: 210px;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
}

// * ---------------- Customer Wish List Page {{ END }} --------------- *

// * ---------------- Customer Quotation Page {{ START }} --------------- *
.main-parent-quotation-page-div {
  .product-details-title {
    font-size: 19px;
    font-weight: 500;
    color: rgba(65, 63, 63, 0.874);
  }

  .quotation-product-div {
    padding: 1px 10px;

    span {
      font-size: 14px;
      font-weight: 500;
    }

    .opacity-span {
      opacity: 0.7;
      font-weight: 400 !important;

    }
  }

  .product-wrapper-main-div {
    .product-box-div {
      border: 1px solid rgba(128, 128, 128, 0.276);
      margin: 10px;
      padding-bottom: 10px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .product-attrbiute-options-heading {
      padding-left: 10px;
      font-size: 12px;
      opacity: 0.6;
      color: black;
      background: #8080801c;
      margin-bottom: 4px;
      padding-bottom: 5px;
      padding-top: 5px;
      vertical-align: middle;
      border-bottom: 1px solid rgba(128, 128, 128, 0.271)
    }

    .product-name-div {
      padding-left: 10px;
      font-size: 13px;
      font-weight: 500;
      color: black;
      padding-bottom: 5px;
      vertical-align: middle;
      border-bottom: 1px solid rgba(128, 128, 128, 0.271);
    }

    .style-master-text-div {
      text-transform: capitalize;
      font-size: 13px;
      font-weight: 400;
    }

    .wrapper-common-div {
      padding: 0 10px;

    }
  }

  .maintaine-max-height {
    height: 212px;
    overflow: auto;
  }

  .product-quotation-page-div {
    font-weight: 500;

    input {
      font-weight: 500;
    }
  }

  .wrapper-div {
    margin-bottom: 5px;

    .common-name-span {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      padding-bottom: 10px;
      padding-right: 2px;
      color: #343434d9;
    }

    .option-span {
      color: rgba(0, 0, 0, 0.84);
      padding-left: 2px;
    }
  }

  .thead-text {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .td-text {
    font-size: 14px;
    font-weight: 500;
  }

  .main-wrapper-cards {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: 20px;

    .common-quotation-card {
      border: 1px solid #80808040;
      box-shadow: rgba(99, 99, 99, 0.125) 0px 2px 3px 0px;

      .wrap-product-quotation-div {
        padding: 5px 10px 5px 20px;
        border-bottom: 1px solid #8080805e;
      }

      .date-and-status-div {
        display: flex;
        height: 40px;
        // padding: 0 20px;
        align-items: center;

        .wrap-div {
          border-right: 1px solid rgba(128, 128, 128, 0.705);
          margin-right: 20px;
          padding-right: 20px;
        }

        .wrap-div:last-child {
          border: none;
        }

        .label-text {
          font-size: 15px;
          font-weight: 500;
          color: #1d1c1ceb;
        }

        .item-text {
          text-transform: capitalize;
          font-size: 15px;
          font-weight: 500;
          margin-left: 5px;
        }
      }

    }
  }

  .customer-pagination-div {
    background-color: white;
    padding: 15px 15px 0 15px;
    margin-bottom: 10px;
  }
}

.accodian-body-section {
  .accordion-body {
    padding: 4px !important;
  }

}

// * ---------------- Customer Quotation Page {{ END }} --------------- *

// * ----------------Admin Quotation Page {{ START }} --------------- *
.wrapper-admin-quotation-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.wrapper-quotation-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.wrap-quotation-cutomer-details-div {

  border-bottom: 1px solid rgba(128, 128, 128, 0.173);
  margin-bottom: 10px;
}

.product-quotation-input-width {
  input {
    width: 130px;
  }
}

.product-quotation-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .maintain-height-quotation {
    max-height: 260px;
    overflow: auto;
  }
}

.customer-product-quotation-select {
  width: 200px;
}

// * ----------------Admin Quotation Page {{ END }} --------------- *

// * ----------------  Customer Profile Page {{ START }} --------------- *
.parent--customer--profile--container {
  margin-top: 50px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;

  .customer-basic-info-text {
    font-size: 25px;
  }

  .business-card-main-img {
    width: 350px;
    height: 200px;
    object-fit: cover;
    border: 2px solid #0000000d;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .customer-email {
    font-size: 18px;
  }

  .customer-address {
    font-size: 15px;
  }

  .font-size-apply {
    font-size: 15px;
  }

  .facebook-h6 {
    font-size: 25px;
    margin-right: 10px;
  }

  .text-paragraph {
    font-size: 17px;
  }

  .rendred-cutomer-information-div {
    .wrapper-label-div {
      width: 210px;
    }

    .span-text {
      font-size: 15px;
      font-weight: 400;
      padding-right: 10px;
      color: #585454;
    }

    .wrapper-border-div {
      border: 1px solid #ced4da;
      padding: 7px 20px 7px 20px;
      user-select: none;
      border-radius: 5px;
      min-width: 250px;
    }

    .span-value {
      font-size: 15px;
      font-weight: 400;
      color: #585454;
    }

    .wrapper-img-business {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border: 1px solid gray;
      margin-right: 10px;
    }

    .wrapper-input-file {
      width: 280px;
    }
  }
}

.customer-details-h4 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid #80808024;
}

.grid-layout-set {
  grid-template-columns: 1fr 1fr;
  gap: 18px 0;
  margin-bottom: 25px;
}

.wrapper-cusotmer-details-section {
  .main-top-hear-label {
    width: 210px;
  }

  .wrapper-text-input-div {
    div {
      margin-bottom: 0 !important;
    }
  }

  .label-span {
    font-size: 15px;
    font-weight: 400;
    padding-right: 10px;
    color: #585454;
  }

  .label-value-div {
    width: 250px;
    padding: 7px 0 7px 20px;
    user-select: none;
    border: 1px solid #ced4da;
    border-radius: 5px;
  }

  .label-value {
    font-size: 15px;
    font-weight: 400;
    color: #585454;
  }
}

// * ----------------  Csutomer Profile Page {{ END }} --------------- *

// * ---------------- Customer Catalog Page {{ START }} --------------- *
.main-customer-catalog-page-div {
  .catalog-details-title {
    font-size: 23px;
    font-weight: 500;
    margin-top: 8px;
    color: rgba(65, 63, 63, 0.874);
    padding-bottom: 5px;
  }

  .product-details-items {
    font-size: 16px;
    font-weight: 400;
    color: rgba(65, 63, 63, 0.874);
  }

  .wrapper-catalog-container {
    padding: 0 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px 20px;

    .catalog-card {
      border: 1px solid #e9e5e5;
      border-radius: 10px;

      .catalog-product-img-div {
        width: 100%;
        height: 550px;
        position: relative;

        .delete-icon-div {
          position: absolute;
          top: 10px;
          right: 15px;
          background-color: rgb(243, 240, 240);
          border-radius: 50px;
          width: 35px;
          height: 35px;
          cursor: pointer;

          i {
            font-size: 17px;
            color: red;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px 10px 0 0;
        }
      }

      .wrapper-catalog-details-div {
        padding-bottom: 8px;
      }

      .catalog-details-div {
        margin: 12px 12px;
        height: 125px;
        overflow: auto;

        .label-span {
          font-size: 15px;
          font-weight: 500;
          color: black;
          width: 110px;
        }

        .two-dots-span {
          font-size: 16px;
          color: black;
          font-weight: 600;
          margin-right: 10px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          // width: 300px;
          color: #4B4A42;
          line-height: 20px;
          height: 40px;
        }

        .catalog-name-span {
          font-size: 20px;
          font-weight: 600;
          color: #1A1A1A;
          padding-bottom: 5px;
        }

        .send-url-icon {
          transform: rotateY(180deg);
          background-color: var(--dark-green);
          border-radius: 100px;
          width: 32px;
          height: 32px;

          i {
            color: white;
            font-size: 22px;
          }
        }

        .pdf-url-text-div {
          text-decoration: underline;
          font-size: 15px;
          color: #073A3D;
          font-weight: 500;

        }
      }
    }
  }
}

// * ---------------- Customer Catalog Page {{ END }} --------------- *

// * ----------------  Attributes Page {{ START }} --------------- *
.select-options-div {
  width: 90%;
  margin-right: 20px;
}

.attributes-btn {
  margin-top: 10px;
  width: 38px;
  height: 36px;
  background-color: #405489;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}


.accodian-section-detail-div {
  display: flex;
  align-items: center;

  .quotation-date-div {
    width: 300px;
  }

  .main-status-div {
    width: 200px;

  }

  span {
    font-size: 14px;
    font-weight: 600 !important;
  }

  .label-span {
    color: #4B4A4299 !important;
  }

  .quo-details-span {
    color: #1A1A1A !important;
  }
}

.quotation-status-span {
  padding: 5px 10px;
  font-size: 14px !important;
  text-transform: capitalize;
  font-weight: 600 !important;
}

.view-product-div {
  position: absolute;
  right: 40px;
  top: 20px;

  span {
    color: #1A1A1A !important;
    font-weight: 600 !important;
  }
}

.pending-color {
  color: red !important;
}

.completed-color {
  color: green !important;
}

.t-head-color-set {
  background-color: #f3f3f9;
  color: #212121;
}

.table-body-wrap {
  tr {
    td {
      font-size: 14px;
      font-weight: 500;
      color: #100e0e;
    }
  }
}

.attributes-table-div {
  min-height: 300px;
}

.catalog-attributes-table-div {
  max-height: 300px;
  overflow: auto;

  thead {
    position: sticky;
    top: 0;
  }
}

// * ----------------  Attributes Page {{ END }} --------------- *

// * ----------------  Sub Category Page {{ START }} --------------- *
.sub-category-modal {
  --vz-modal-width: 600px !important;
}

// * ----------------  Sub Category Page {{ END }} --------------- *

// * ----------------  Admin Dashboard {{ START }} --------------- *

.widgets-month-span {
  font-size: 12px;
  padding-bottom: 10px;
  font-weight: 500;
  color: gray;
}

.widgets-left-side-div {
  font-weight: 600;
  margin-bottom: 8px;
  color: #495057;
  // margin-right: 5px;
  padding-right: 5px;
}

.widgets-right-side-div {
  font-weight: 600;
  margin-bottom: 8px;
  color: #495057;
  margin-left: 5px;
  padding-left: 10px;
}

.widgets-width-div {
  width: 90px;
}

.vertical-border-widgets {
  height: 55px;
  width: 1px;
  background-color: rgba(128, 128, 128, 0.805);
}

.navigate-span-dashboard {
  width: 30px;
  height: 30px;
}

// * ----------------  Admin Dashboard {{ END }} --------------- *

// * ----------------  Responsive Design ( Media Quaryes ) {{ START }} ------------- *
@media (max-width: 1441px) {
  .main--parent--login--div {

    .main-input-padding-set {
      padding: 0px 50px;
    }
  }

  .main--customer--registration--div {

    .main-input-padding-set {
      padding: 0px 30px;
    }
  }

  .customer--product--card-body {
    grid-template-columns: 1fr 1fr !important;
    gap: 20px !important;
  }
}

@media (max-width: 1025px) {
  .customer--product--card-body {
    grid-template-columns: 1fr !important;
  }
}

// * ----------------  Responsive Design ( Media Quaryes ) {{ END }} --------------- *